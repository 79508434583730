import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { LoadingComponent } from "../../components/LoadingComponent";
import { TitleComponent } from "../../components/TitleComponent";
import { PaginationComponent } from "../../components/PaginationComponent";
import {
  declarationStatusColor,
  DeclarationStatusComponent,
  declarationStatusesFilters,
  declarationStatusesFiltersColors,
} from "../../components/DeclarationStatusComponent";
import { Api } from "../../api";
import { formatDate } from "../../config";
import { SearchComponent } from "../../components/SearchComponent";
import { UserContext } from "../../store";
import moment from "moment";

export const DeclarationsList = () => {
  let [declarations, setDeclarations] = useState([]);
  let [error, setError] = useState(false);
  let [loading, setLoading] = useState(true);
  let [pageCount, setPageCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(0);
  let [status, setStatus] = useState("");
  let [query, setQuery] = useState("");
  let { user } = useContext(UserContext);

  useEffect(() => {
    init();

    return () => {};
  }, [status, query, currentPage]);

  let init = () => {
    setLoading(true);
    setError(false);
    Api.get(`declarations?q=${query}&page=${currentPage}&status=${status}`)
      .then((result) => {
        setDeclarations(result.declarations);
        setPageCount(result.total_pages);
        setError(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
        setError(true);
      });
  };

  let handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  let changeStatusFilter = (e) => {
    let value = e.target.value;
    setStatus(value);
  };

  let search = (e) => {
    setQuery(e.target.value);
  };

  let statuses = declarationStatusesFilters(user.role);

  return (
    <div className="bg-white p-3 rounded-3">
      <div className="align-items-center pe-1 row">
        <div className="col-md-4">
          <TitleComponent text="Les déclarations" showBackButton={false} />
        </div>
        <div className="col-md-8">
          <div className="d-flex justify-content-md-end align-items-center justify-content-center mb-3">
            {Object.keys(statuses).map((item) => (
              <button
                key={item}
                value={item}
                className={`btn mx-1 ${
                  declarationStatusesFiltersColors[item]
                } ${item === status ? "active shadow" : ""}`}
                onClick={changeStatusFilter}
              >
                {statuses[item]}
              </button>
            ))}
          </div>
        </div>
      </div>

      <SearchComponent onChange={search} query={query} />
      <div className="mx-2">
        <LoadingComponent
          loading={loading}
          error={error}
          hasData={declarations.length > 0}
          onRetry={init}
          isTable={false}
        >
          <div className="table-responsive">
            <table className="table border declarations-table">
              <thead
                className="align-middle text-center"
                style={{ backgroundColor: "rgb(245 245 245)" }}
              >
                <tr>
                  <th className="text-nowrap py-2">Numéro</th>
                  <th className="text-nowrap py-2">Déclarant</th>
                  {user.role !== "org" ? (
                    <th className="text-nowrap py-2">Analyst</th>
                  ) : (
                    <></>
                  )}
                  <th className="text-nowrap py-2">Type de déclaration</th>
                  <th className="text-nowrap py-2">Date</th>
                  <th className="text-nowrap py-2">Type de soupçons</th>
                  <th className="text-nowrap py-2">Statut</th>
                  <th className="text-nowrap py-2"></th>
                </tr>
              </thead>

              <tbody>
                {declarations.map((declaration) => {
                  return (
                    <tr
                      className="align-middle text-center"
                      key={declaration.id}
                    >
                      <td className="text-nowrap">
                        {`${declaration.id}`.padStart(3, "0")}/
                        {moment(declaration.created_at).format("YYYY")}
                      </td>
                      <td className="text-nowrap">{declaration.org_name}</td>
                      {user.role !== "org" ? (
                        <td className="text-nowrap">
                          {declaration.analytics_name
                            ? declaration.analytics_name
                            : "Non"}
                        </td>
                      ) : (
                        <></>
                      )}

                      <td className="">{declaration.report_type}</td>
                      <td className="text-nowrap">
                        {formatDate(
                          declaration.submission_date
                            ? declaration.submission_date
                            : declaration.created_at
                        )}
                      </td>
                      <td className="">{declaration.suspicion_type}</td>
                      <td className="text-nowrap">
                        <DeclarationStatusComponent
                          userType={user.role}
                          status={declaration.status}
                        />
                      </td>
                      <td className="text-nowrap px-3">
                        <Link
                          to={`/declarations/${
                            user.role === "org"
                              ? `create/${declaration.id}`
                              : `show/${declaration.id}`
                          }`}
                          className="btn btn-primary"
                        >
                          <i className="fa fa-pencil" />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </LoadingComponent>
      </div>
      <PaginationComponent
        handlePageClick={handlePageClick}
        pageCount={pageCount}
      />
    </div>
  );
};
