import React, { useEffect, useState } from "react";
import { formFieldsFr, sectionsFr } from "../../../data/data";
import { DataInput } from "../../../components/DataInput";
import { FireToast } from "../../../config";
import { Uploader } from "../../../components/Uploader";
import { FileComponent } from "../../../components/FileComponent";
import { Api } from "../../../api";
import { useNavigate } from "react-router-dom";
import logo from "../../../logo.png";
import { SectionWrapper } from "./SectionWrapper";

export const DeclarationForm = ({
  readOnly,
  post,
  savedPost,
  user,
  setFiles,
  files,
  onUpdateDeclaration,
  setPost,
}) => {
  let [saving, setSaving] = useState(false);
  let [isDirty, setIsDirty] = useState(false);

  const navigate = useNavigate();

  if (user.role === "manager") return <></>;

  useEffect(() => {
    console.log("in auto save effect");
    if (isDirty) {
      debouncedSave();
    }
  }, [post, isDirty]);

  let savePost = async () => {
    setSaving(true);

    try {
      let result;
      if (post.id) {
        result = await Api.put(`declarations/${post.id}`, {
          declaration: post,
          attachments_ids: files.map((f) => f.id),
        });
      } else {
        result = await Api.post(`declarations`, {
          declaration: post,
          attachments_ids: files.map((f) => f.id),
        });
      }

      if (result.status === "ok") {
        FireToast("Les données ont été enregistrées avec succès", "success");
        if (!post.id) {
          onUpdateDeclaration({ ...post, ...result.declaration });
          navigate(`/declarations/create/${result.declaration.id}`);
        } else {
          setFiles([...result.attachments]);
        }
        setIsDirty(false);
        clearDebounce();
      } else {
        FireToast("Erreur Les données n'ont pas été enregistrées!", "error");
      }
    } catch (err) {
      console.log("error", err);
      FireToast("Erreur Les données n'ont pas été enregistrées!", "error");
    }
    setSaving(false);
  };

  const debouncedSave = debounce(savePost, 6000);

  let submitPost = async () => {
    setSaving(true);

    try {
      let result = await Api.get(`declarations/${post.id}/submit`);

      if (result.status === "ok") {
        FireToast("Les données ont été envoyées à UMEF.", "success");
        onUpdateDeclaration({ ...post, status: result.declaration_status });
        setFiles([...result.attachments]);
      } else {
        FireToast("Erreur Les données n'ont pas été enregistrées!", "error");
      }
    } catch (err) {
      console.log("error", err);
      FireToast(
        "Une erreur est survenue, veuillez réessayer plus tard.",
        "error"
      );
    }
    setSaving(false);
  };

  let onFieldChanged = (keyName, value) => {
    setPost({
      ...post,
      [keyName]: value,
    });
    setIsDirty(true);
  };

  let onFileDeleted = (file) => {
    let item = files.find((item) => item.id === file.id);
    let index = files.indexOf(item);
    let oldFiles = [...files];
    if (index !== -1) {
      oldFiles.splice(index, 1);
    }
    setFiles([...oldFiles]);
  };

  let myFiles = files.filter((f) => f.user_id === savedPost.org_id);

  return (
    <SectionWrapper
      name="form"
      show={
        (user.role === "analytics" && post.status === "submitted") ||
        user.role === "org"
      }
      titleComponent={
        <div
          style={{ width: "auto" }}
          className="avatar avatar-xl justify-content-center align-items-center"
        >
          {/* <img
            className="avatar-img me-2"
            src={logo}
            style={{ width: "70px", maxWidth: 70, height: "auto" }}
          /> */}
          <span>{post.org_name}</span>
        </div>
      }
    >
      <ol className="p-0" type="I">
        {Object.keys(sectionsFr).map((sectionKey) =>
          readOnly && sectionKey === "documents_to_provide" ? (
            <></>
          ) : (
            <div className="card border-0 mt-3" key={sectionKey}>
              <div className="card-body p-0">
                <div className="form-group mb-0">
                  <li className="ms-4">
                    <div
                      htmlFor="name"
                      className="font-weight-bold bg-light mb-2 p-2 rounded d-inline-block"
                    >
                      {typeof sectionsFr[sectionKey] === "string"
                        ? sectionsFr[sectionKey]
                        : sectionsFr[sectionKey].name}
                    </div>
                  </li>

                  {typeof sectionsFr[sectionKey] !== "string" ? (
                    <ol className="p-0">
                      {Object.keys(sectionsFr[sectionKey].options).map(
                        (subSectionKey) => (
                          <div
                            className="card mt-3 border-0"
                            key={subSectionKey}
                          >
                            <div className="card-body">
                              <div className="form-group mb-0">
                                <li className="ms-3">
                                  <div
                                    htmlFor="name"
                                    className="font-weight-bold bg-light mb-2 p-2 rounded d-inline-block"
                                  >
                                    {
                                      sectionsFr[sectionKey].options[
                                        subSectionKey
                                      ]
                                    }
                                  </div>
                                </li>
                                <table className="table table-bordered table-condensed">
                                  <tbody>
                                    {Object.keys(
                                      formFieldsFr[sectionKey][subSectionKey]
                                    ).map((itemKey) => (
                                      <DataInput
                                        key={itemKey}
                                        itemKey={itemKey}
                                        label={
                                          formFieldsFr[sectionKey][
                                            subSectionKey
                                          ][itemKey]
                                        }
                                        post={post}
                                        savedPost={savedPost}
                                        onFieldChanged={onFieldChanged}
                                        defaultReadOnly={readOnly}
                                      />
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </ol>
                  ) : (
                    <table className="table table-bordered table-condensed">
                      <tbody>
                        {Object.keys(formFieldsFr[sectionKey]).map(
                          (itemKey) => (
                            <DataInput
                              key={itemKey}
                              itemKey={itemKey}
                              label={formFieldsFr[sectionKey][itemKey]}
                              post={post}
                              savedPost={savedPost}
                              onFieldChanged={onFieldChanged}
                              defaultReadOnly={readOnly}
                            />
                          )
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          )
        )}
      </ol>
      <Uploader
        readOnly={readOnly}
        onFilesChanged={(newFiles) => {
          setFiles([...files, ...newFiles]);
          setIsDirty(true);
        }}
      />
      {!saving ? (
        <table className="table bg-white table-bordered table-condensed my-2">
          <tbody>
            {myFiles.map((file) => (
              <FileComponent
                key={file.id || file.name}
                defaultFile={file}
                declaration={savedPost}
                onDeleted={onFileDeleted}
                readOnly={readOnly}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <></>
      )}

      {readOnly ? (
        <></>
      ) : (
        <div className="mt-5">
          <button
            className="btn btn-lg btn-info text-white  shadow-none mx-2"
            onClick={savePost}
            disabled={saving}
          >
            Enregistrer
          </button>

          {!!post.id && post.status === "draft" ? (
            <button
              className="btn btn-lg btn-primary  shadow-none mx-2"
              onClick={submitPost}
              disabled={saving}
            >
              Envoyer les données à UMEF
            </button>
          ) : (
            <></>
          )}
        </div>
      )}
    </SectionWrapper>
  );
};

let timeout;
function clearDebounce() {
  clearTimeout(timeout);
}
function debounce(func, delay) {
  return function (...args) {
    clearDebounce();
    timeout = setTimeout(() => func.apply(this, args), delay);
  };
}
