import React, { useEffect, useState } from "react";
import { Api } from "../api";
import { LoadingComponent } from "../components/LoadingComponent";
import { declarationStatuses } from "../components/DeclarationStatusComponent";

export const HomePage = () => {
  let [error, setError] = useState(false);
  let [loading, setLoading] = useState(true);
  let [statuses, setStatuses] = useState({});
  let [orgs, setOrgs] = useState({});

  useEffect(() => {
    init();
  }, []);

  let init = () => {
    setLoading(true);
    setError(false);
    Api.get(`numbers`)
      .then((result) => {
        // declarations_by_org: declarations, declarations_status
        setStatuses(result.declarations_status);
        setOrgs(result.declarations_by_org);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <div className="bg-white rounded-3 p-4">
      <LoadingComponent
        loading={loading}
        error={error}
        hasData={true}
        isTable={false}
        onRetry={init}
      >
        <h2>Aperçu des Statistiques </h2>
        <div className="orders-chars px-2 py-2">
          <div className="md-col-8 mx-auto mb-3"></div>
          <div className="md-col-8 mx-auto mb-5">
            <div
              className="card bg-light border-0"
              style={{
                borderRadius: "20px",
                boxShadow:
                  "rgb(0 0 0 / 6%) 8px 8px 20px, rgb(255 255 255 / 17%) -8px -8px 20px",
                padding: "0.3rem",
                transition: "transform 0.3s ease 0s, box-shadow 0.3s ease 0s",
                position: "relative",
                overflow: "hidden",
                background:
                  "linear-gradient(307deg, rgb(108 196 102 / 4%), rgba(129, 199, 132, 0.05))",
              }}
            >
              <div className="card-body">
                <h4
                  className="card-title text-center mb-3"
                  style={{ color: "#333", fontWeight: "bold" }}
                >
                  Statuts Totals
                </h4>
                <div className="row justify-content-center align-items-center">
                  {Object.keys(statuses).map((key) => (
                    <div key={key} className="col-md-4">
                      <div
                        className="card text-white m-2"
                        style={{
                          background: statusColors[key],
                          borderRadius: "20px",
                          boxShadow:
                            "8px 8px 20px rgba(0, 0, 0, 0.1), -8px -8px 20px rgba(255, 255, 255, 0.7)",
                          transition: "transform 0.3s, box-shadow 0.3s",
                          padding: "1.5rem",
                          textAlign: "center",
                          position: "relative",
                        }}
                      >
                        <h3 style={{ fontWeight: "bold", color: "#fff" }}>
                          {declarationStatuses("analytics")[key]}
                        </h3>
                        <p
                          style={{
                            fontSize: "3.5rem",
                            fontWeight: "bold",
                            color: "#fff",
                            margin: 0,
                          }}
                        >
                          {statuses[key]}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {Object.keys(orgs).map((key) => (
              <div key={key} className="col-md-4">
                <div
                  className="card bg-light border-0 m-4"
                  style={{
                    borderRadius: "20px",
                    boxShadow:
                      "8px 8px 20px rgba(0, 0, 0, 0.1), -8px -8px 20px rgba(255, 255, 255, 0.7)",
                    padding: "1.5rem",
                    transition: "transform 0.3s, box-shadow 0.3s",
                    position: "relative",
                    overflow: "hidden",
                    background:
                      "linear-gradient(135deg, rgba(107,115,255,0.1), rgba(0,13,255,0.05))",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: "100%",
                      width: "8px",
                      background:
                        "linear-gradient(to bottom, #6b73ff, #000dff)",
                      borderTopLeftRadius: "20px",
                      borderBottomLeftRadius: "20px",
                    }}
                  ></div>
                  <div className="card-body">
                    <h3
                      className="card-title"
                      style={{ color: "#333", fontWeight: "bold" }}
                    >
                      {key}
                    </h3>
                    {Object.keys(orgs[key]).map((jkey) => (
                      <div
                        key={jkey}
                        className="d-flex justify-content-between mb-3"
                      >
                        <span>{declarationStatuses("analytics")[jkey]}:</span>
                        <span>{orgs[key][jkey]}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </LoadingComponent>
    </div>
  );
};

let statusColors = {
  process: "linear-gradient(135deg, #4CAF50 30%, #81C784 100%)",
  done: "linear-gradient(135deg, #9C27B0 30%, #BA68C8 100%)",
  closed: "linear-gradient(135deg, rgb(52 51 50) 30%, rgb(76 75 74) 100%)",
  submitted: "linear-gradient(135deg, #2196F3 30%, #64B5F6 100%)",
};

// Green Gradient (For Success/Completed Statuses):

// css

// background: linear-gradient(135deg, #4CAF50 30%, #81C784 100%);

// Blue Gradient (For Information/Process Statuses):

// css

// background: linear-gradient(135deg, #2196F3 30%, #64B5F6 100%);

// Orange Gradient (For Warnings/Submitted Statuses):

// css

// background: linear-gradient(135deg, #FF9800 30%, #FFB74D 100%);

// Red Gradient (For Errors/Closed Statuses):

// css

// background: linear-gradient(135deg, #F44336 30%, #E57373 100%);

// Purple Gradient (For Custom/Unique Statuses):

// css

// background: linear-gradient(135deg, #9C27B0 30%, #BA68C8 100%);
