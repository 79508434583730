import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TitleComponent } from "../../components/TitleComponent";
import { FireToast } from "../../config";
import { Api } from "../../api";
import { users_type } from "../../data/data";

export const AddUser = () => {
  let [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    role: "user",
    status: "active",
  });
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState(false);
  let [sending, setSending] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    return () => {};
  }, []);

  let submit = (e) => {
    e.preventDefault();
    if (!user.password) {
      FireToast("Le mot de passe est obligatoire", "error");
      return;
    }
    if (!user.email) {
      FireToast("L'email est obligatoire", "error");
      return;
    }

    setSending(true);
    Api.post(`users`, {
      user: {
        name: user.name,
        email: user.email,
        password: user.password,
        status: user.status,
        role: user.role,
      },
    })
      .then((result) => {
        setSending(false);
        if (result.status == "ok") {
          FireToast("Les informations de l'utilisateur ont été ajoutées.");
          // navigate(`/users/show/${result.user.id}`, { replace: true });
          navigate(`/users`, { replace: true });
        } else {
          FireToast(result.message, "error");
        }
      })
      .catch((err) => {
        setSending(false);
        FireToast("Une erreur s'est produite, veuillez réessayer.", "error");
      });
  };

  let onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  return (
    <>
      <TitleComponent text="Créer un compte" />

      <form
        onSubmit={submit}
        action=""
        method="post"
        className="col-md-6 mx-auto p-3"
      >
        {/* <div className="mb-3">
          <label htmlFor="image" className="form-label">
            Photo
          </label>
          <input
            type="file"
            className="form-control"
            id="image"
            name="image"
            placeholder="Photo"
            value={user.image}
            onChange={onChange}
          />
        </div> */}
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Nom
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            placeholder="Nom"
            value={user.name}
            onChange={onChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            E-mail
          </label>
          <input
            type="text"
            className="form-control"
            id="email"
            name="email"
            placeholder="E-mail"
            value={user.email}
            onChange={onChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Mot de passe
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            placeholder="Mot de passe"
            value={user.password}
            onChange={onChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="role" className="form-label">
            Type d'utilisateur
          </label>
          <select
            className="form-select"
            id="role"
            name="role"
            value={user.role}
            onChange={onChange}
          >
            {Object.keys(users_type).map((item) => (
              <option key={item} value={item}>
                {users_type[item]}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="status" className="form-label">
            Statut de l'utilisateur
          </label>
          <select
            className="form-select"
            id="status"
            name="status"
            value={user.status}
            onChange={onChange}
          >
            <option value="disable">Bloquer</option>
            <option value="active">Activer</option>
          </select>
        </div>
        <div className="text-center">
          <button
            type="submit"
            className="btn btn-primary text-center"
            disabled={sending}
          >
            Ajouter
          </button>
        </div>
      </form>
    </>
  );
};
