import React, { useEffect, useState } from "react";
import { Api } from "../../../api";
import { ChatFormComponent } from "../../../components/ChatFormComponent";
import { LoadingComponent } from "../../../components/LoadingComponent";
import { MessageComponent } from "../../../components/MessageComponent";
import { FireToast, formatDate } from "../../../config";
import { SectionWrapper } from "./SectionWrapper";

export const DeclarationChats = ({ declaration, readOnly, user }) => {
  let [loading, setLoading] = useState(true);
  let [error, setError] = useState(false);
  let [sending, setSending] = useState(false);
  let [messages, setMessages] = useState([]);
  let [message, setMessage] = useState("");

  let intervalValue;

  useEffect(() => {
    if (declaration.id && declaration.status !== "draft") {
      init();
    }

    return () => {
      clearNotificationInterval();
    };
  }, []);

  let init = () => {
    setLoading(true);
    setError(false);
    Api.get(`declarations/${declaration.id}/messages`)
      .then((result) => {
        let oldMessages = result.messages || [];
        setMessages(oldMessages);
        messages = oldMessages;
        setLoading(false);
        // setNotificationInterval();
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setError(true);
      });
  };

  let submit = (e) => {
    e.preventDefault();
    if (!message) {
      FireToast("Veuillez écrire votre message");
      return;
    }
    setSending(true);
    Api.post(`declarations/${declaration.id}/messages`, {
      message: message,
    })
      .then((result) => {
        setMessage("");
        let oldMessages = [...messages];
        oldMessages.unshift(result.item);
        setMessages(oldMessages);
        messages = oldMessages;
        setSending(false);
      })
      .catch((err) => {
        setSending(false);
        FireToast("Une erreur est survenue, veuillez réessayer plus tard");
      });
  };

  let setNotificationInterval = () => {
    clearNotificationInterval();
    intervalValue = setInterval(() => {
      Api.get(`declarations/${declaration.id}/messages?refresh=1`)
        .then((result) => {
          let oldMessages = [...messages];
          result.messages.forEach((item) => {
            if (
              oldMessages.find((fitem) => fitem.id == item.id) === undefined
            ) {
              oldMessages.unshift(item);
            }
          });
          setMessages(oldMessages);
          messages = oldMessages;
        })
        .catch((err) => {});
    }, 5000);
  };

  let clearNotificationInterval = () => {
    if (intervalValue) {
      clearInterval(intervalValue);
    }
  };

  if (
    !declaration.id ||
    user.role === "manager" ||
    ["draft", "submitted"].includes(declaration.status)
  )
    return <></>;

  return (
    <SectionWrapper
      name="chats"
      show={user.role === "org"}
      titleComponent={<h2 className="m-0 py-2">Discussions</h2>}
    >
      <div id="discussions">
        {["closed", "done", "submitted", "review", "draft"].indexOf(
          declaration.status
        ) === -1 ? (
          <ChatFormComponent
            message={message}
            setMessage={setMessage}
            onSend={submit}
            sending={sending}
          />
        ) : (
          <></>
        )}
        <LoadingComponent
          loading={loading}
          error={error}
          onRetry={init}
          isTable={false}
          hasData={messages.length > 0}
        >
          <div className="pb-2">
            {messages.map((message) => (
              <MessageComponent
                key={message.id}
                message={message}
                declaration={declaration}
              />
            ))}
          </div>
        </LoadingComponent>
      </div>
    </SectionWrapper>
  );
};
