import React, { useContext, useEffect, useState } from "react";
import { Api } from "../../api";

import { useParams, useNavigate, Link } from "react-router-dom";
import { LoadingComponent } from "../../components/LoadingComponent";
import { DeclarationStatusComponent } from "../../components/DeclarationStatusComponent";
import { UserContext } from "../../store";

import { DeclarationChats } from "./Actions/DeclarationChats";
import { DownloadPDFAction } from "./Actions/DownloadPDFAction";
import { RelatedDeclarations } from "./Actions/RelatedDeclarations";
import { ManagerActions } from "./Actions/ManagerActions";
import { ReportAction } from "./Actions/ReportAction";
import { AssignToMe } from "./Actions/AssignToMe";
import { DeclarationForm } from "./Actions/DeclarationForm";

export const AddDeclaration = ({ readOnly = false }) => {
  let [post, setPost] = useState({});
  let [savedPost, setSavedPost] = useState({});
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState(false);

  let [files, setFiles] = useState([]);
  let [related, setRelated] = useState([]);

  let { user } = useContext(UserContext);

  let { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setPost({});
    setSavedPost({});
    setFiles([]);

    init();

    return () => {};
  }, [readOnly, id]);

  let init = () => {
    if (!id) return;

    setLoading(true);
    setError(false);
    Api.get(`declarations/${id}`)
      .then((result) => {
        if (
          result.declaration.status === "draft" &&
          user.id !== result.declaration.org_id
        ) {
          navigate("/declarations");
          return;
        }

        onUpdateDeclaration(result.declaration);

        setFiles([...result.attachments]);
        setLoading(false);
        setRelated(result.related);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  };

  let onUpdateDeclaration = (newPost) => {
    setPost({ ...newPost });
    setSavedPost({ ...newPost });
  };

  if (
    !readOnly &&
    (["closed", "done"].includes(savedPost.status) ||
      (user.id !== savedPost.org_id && !!savedPost.org_id))
  ) {
    readOnly = true;
  }

  return (
    <div
      id="element-to-print"
      className=""
      style={{ backgroundColor: "#eaf0f6" }}
    >
      <div
        className="pt-3 "
        id="element-to-prin-inner"
        style={{ backgroundColor: "#eaf0f6" }}
      >
        <div
          className="position-fixed w-50 top-0 px-5 pt-2"
          style={{ zIndex: 9999 }}
        >
          <div className="pe-5 d-flex align-items-center">
            <DownloadPDFAction declaration={savedPost} user={user} />
            {user.role === "analytics" ? (
              <>
                <Link
                  className="btn btn-lg text-white btn-info btn-block shadow-none mx-1"
                  id="declaration-logs"
                  to={`/declarations/show/${savedPost.id}/logs`}
                >
                  <i className="fa fa-history" />
                </Link>
              </>
            ) : (
              <></>
            )}
            <AssignToMe
              declaration={savedPost}
              user={user}
              onUpdateDeclaration={onUpdateDeclaration}
            />
          </div>
        </div>

        <LoadingComponent
          loading={loading}
          error={error}
          onRetry={init}
          isTable={false}
        >
          <div className="d-flex flex-column mx-auto px-md-2 px-2 justify-content-center align-items-center">
            <div className="row w-100 mb-5 text-right">
              <div className="col-md-12 mx-auto">
                <div className="card rounded-3 mb-3">
                  <div className="card-body py-2">
                    <h2 className="card-title mb-3">
                      Formulaire de déclaration de soupçon
                    </h2>
                    <div className="text-dark mb-0">
                      <div className=" text-black-50 h5">
                        <DeclarationStatusComponent
                          userType={user.role}
                          status={post.status}
                        />
                        <span className="mx-1">-</span>
                        <strong className="text-dark">{post.org_name}</strong>
                        {post.analytics_name ? (
                          <>
                            <span className="mx-1">-</span>
                            <strong className="text-dark">
                              {post.analytics_name}
                            </strong>
                          </>
                        ) : (
                          <></>
                        )}
                        {post.submission_date ? (
                          <>
                            <span className="mx-1">-</span>
                            <strong className="text-dark">
                              {post.submission_date}
                            </strong>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <DeclarationForm
                  post={post}
                  savedPost={savedPost}
                  user={user}
                  files={files}
                  setFiles={setFiles}
                  setPost={setPost}
                  onUpdateDeclaration={onUpdateDeclaration}
                  readOnly={readOnly}
                />

                <DeclarationChats
                  declaration={savedPost}
                  readOnly={readOnly}
                  user={user}
                />

                <RelatedDeclarations
                  declaration={savedPost}
                  user={user}
                  related={related}
                />

                <ReportAction
                  declaration={savedPost}
                  user={user}
                  onUpdateDeclaration={onUpdateDeclaration}
                  files={files}
                  setFiles={setFiles}
                />

                <ManagerActions
                  declaration={savedPost}
                  user={user}
                  onUpdateDeclaration={onUpdateDeclaration}
                />
              </div>
            </div>
          </div>
        </LoadingComponent>
      </div>
    </div>
  );
};
