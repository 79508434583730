export const categories_type = {
  service: "خدمة",
  category: "قسم",
};

// analytics: "analytics", admin: "admin", org: "org", manager: "manager"
export const users_type = {
  analytics: "Analyst",
  admin: "Administrateur",
  org: "Organisation",
  manager: "Directeur",
};

export const formFieldsAr = {
  // submission_details: {
  //   submission_date: "تاريخ الإرسال",
  // },
  reporting_entity: {
    reporting_entity_name: "اسم الجهة",
    reporting_entity_type: "نوع الجهة",
    reporting_entity_address: "عنوان الجهة",
    branch_name: "الفرع/الوكالة الذي حدث فيه الاشتباه",
    branch_code: "كود الفرع/الوكالة",
    branch_address: "عنوان الفرع /الوكالة",
    control_authority_name: "اسم الجهة الرقابية",
    control_authority_registration_number: "رقم التسجيل لدى الجهة الرقابية",
  },
  declaration_responsible_person: {
    responsible_person_name: "الاسم",
    responsible_person_position: "الوظيفة",
    responsible_person_phone_number: "رقم (أرقام) الهاتف",
    responsible_person_email: "البريد الإلكتروني",
  },
  suspicion_declaration: {
    report_type: "نوع البلاغ",
    report_number: "رقم البلاغ",
    report_date: "تاريخ البلاغ",
    suspicion_type: "نوع الاشتباه",
  },
  suspected_individual: {
    individual_info: {
      suspected_person_full_name: "الاسم الكامل",
      suspected_person_nationality: "الجنسية",
      suspected_person_id_type: "نوع الهوية",
      suspected_person_id_issuer: "الجهة المصدرة للهوية",
      suspected_person_id_expiry_date: "تاريخ انتهاء صلاحية الهوية",
      suspected_person_birth_date: "تاريخ الميلاد",
      suspected_person_birth_place: "مكان الميلاد",
      suspected_person_gender: "الجنس",
      suspected_person_marital_status: "الحالة الاجتماعية",
      suspected_person_workplace_name: "اسم جهة العمل",
      suspected_person_employer_name: "اسم صاحب العمل",
      suspected_person_employer_address: "عنوان صاحب العمل",
      suspected_person_employer_postal_code: "الرمز البريدي لصاحب العمل",
      suspected_person_employer_phone_number: "رقم هاتف صاحب العمل",
      suspected_person_employer_activity: "نوع نشاط صاحب العمل",
      suspected_person_annual_income: "الدخل السنوي",
      suspected_person_income_source: "مصدر الدخل",
      joint_account_partner_name: "اسم شريك الحساب المشترك",
      joint_account_partner_address: "عنوان شريك الحساب المشترك",
    },
    additional_info_profession: {
      suspected_person_profession_type: "نوع المهنة",
      suspected_person_profession_registration_number: "رقم تسجيل المهنة",
      suspected_person_workplace_address: "عنوان مكان العمل",
      suspected_person_workplace_email: "البريد الإلكتروني لمكان العمل",
      suspected_person_workplace_postal_box: "الصندوق البريدي لمكان العمل",
      suspected_person_workplace_phone_number: "رقم (أرقام) الهاتف للمهنة",
    },
    beneficial_owner_info: {
      beneficial_owner_name: "الاسم",
      beneficial_owner_nationality: "الجنسية",
      beneficial_owner_id_type: "نوع مستند الهوية",
      beneficial_owner_id_number: "رقم مستند الهوية",
      beneficial_owner_relationship_with_suspect:
        "علاقة المستفيد الحقيقي بالشخص المشتبه فيه",
    },
  },
  suspected_entity: {
    entity_info: {
      suspected_entity_commercial_name: "الاسم التجاري",
      suspected_entity_legal_form: "الشكل القانوني",
      suspected_entity_registration_number: "رقم السجل التجاري",
      suspected_entity_creation_date: "تاريخ التأسيس",
      suspected_entity_capital: "رأس المال",
      suspected_entity_activity_description: "وصف النشاط وفقاً للسجل التجاري",
      suspected_entity_address: "العنوان",
      suspected_entity_email: "البريد الإلكتروني",
      suspected_entity_postal_code: "الرمز البريدي",
      suspected_entity_phone_number: "رقم (أرقام) الهاتف",
      suspected_entity_fax_number: "رقم (أرقام) الفاكس",
      suspected_entity_management_names_and_titles: "أسماء وصفة الإدارة العليا",
      authorized_person_names: "المفوضون بالتعامل عن الشخص الاعتباري",
      suspected_entity_relationship_start_date:
        "تاريخ بداية التعامل مع الشخص الاعتباري",
    },
    beneficial_owner_info: {
      entity_beneficial_owner_name: "الاسم",
      entity_beneficial_owner_nationality: "الجنسية",
      entity_beneficial_owner_id_type: "نوع مستند الهوية",
      entity_beneficial_owner_id_number: "رقم مستند الهوية",
      entity_beneficial_owner_relationship_with_entity:
        "علاقة المستفيد الحقيقي بالشخص الاعتباري المشتبه فيه",
    },
  },
  relationship_nature: {
    suspect_name: "اسم المشتبه فيه",
    relationship_type: "نوع العلاقة",
    relationship_still_exists: "هل العلاقة لا زالت قائمة",
  },
  related_persons: {
    linked_person_name: "الاسم",
    linked_person_id_type: "نوع مستند الهوية",
    linked_person_id_number: "رقم مستند الهوية",
    linked_person_relationship_to_transaction: "العلاقة بالعملية المشتبه فيها",
    linked_person_relationship_to_reporting_entity: "العلاقة مع الجهة المبلغة",
  },
  suspected_operation_details: {
    transaction_date: "التاريخ",
    transaction_type: "نوع العملية",
    suspicion_indications_and_reasons: "مؤشرات الاشتباه",
    suspicion_motives: "أسباب الاشتباه",
  },
  suspect_accounts_info: {
    account_type: "نوع الحساب",
    account_opening_date: "تاريخ فتح الحساب",
    account_branch_name: "الفرع المفتوح لديه الحساب",
    account_number: "رقم الحساب",
    account_purpose: "الغرض من التعامل على الحساب",
    account_currency: "عملة الحساب",
    account_balance: "رصيد الحساب",
    account_balance_date: "تاريخ الرصيد",
    account_study_period_start_date: "فترة دراسة الحساب",
    total_credit_movements:
      "إجمالي الحركة الدائنة على الحساب خلال فترة الدراسة",
    total_debit_movements: "إجمالي الحركة المدينة على الحساب خلال فترة الدراسة",
    highest_credit_balance: "أعلى رصيد دائن للحساب خلال الثلاث سنوات السابقة",
    linked_accounts: "الحسابات المرتبطة (تذكر)",
  },
  measures_taken: {
    actions_taken_suspect_name: "اسم المشتبه فيه",
    actions_taken_with_suspect: "الإجراء المتخذ مع الشخص المشتبه فيه",
    additional_actions_taken: "الإجراءات الأخرى المتخذة",
  },
  documents_to_provide: {
    client_identification_documents: "نموذج فتح الحساب",
    client_due_diligence_documents:
      "مستندات التعرف والتحقق الخاصة بالعميل المشتبه فيه والمستفيد الحقيقي",
    other_account_opening_documents:
      "المستندات الأخرى التي تم الحصول عليها عند فتح الحساب أو قيام علاقة العمل",
    account_statements: "كشوف الحسابات",
    suspicious_transaction_copies: "صور العمليات المالية المشتبه فيها",
    additional_related_documents:
      "أية مستندات أخرى تؤيد العملية المشتبه فيها (ذات صلة بالعملية المشتبه فيها)",
  },
};

export const formFieldsFr = {
  // submission_details: {
  //   submission_date: "Date d'envoi",
  // },
  reporting_entity: {
    reporting_entity_name: "Nom de l’entité",
    reporting_entity_type: "Type d'entité",
    reporting_entity_address: "Adresse de l'entité",
    branch_name: "La Branche/l'agence dans laquelle le soupçon s'est produit",
    branch_code: "Code de La Branche/l'agence",
    branch_address: "Adresse de La Branche/l'agence",
    control_authority_name: "Nom de l'autorité de contrôle",
    control_authority_registration_number:
      "Numéro d'enregistrement auprès de l'autorité de contrôle",
  },
  declaration_responsible_person: {
    responsible_person_name: "Nom",
    responsible_person_position: "Poste",
    responsible_person_phone_number: "Le(s) numéro(s) de téléphone",
    responsible_person_email: "Email",
  },
  suspicion_declaration: {
    report_type: "Type de déclaration",
    report_number: "Numéro de la déclaration",
    report_date: "Date de déclaration",
    suspicion_type: "Type de soupçons",
  },
  suspected_individual: {
    individual_info: {
      suspected_person_full_name: "Nom et prénom",
      suspected_person_nationality: "Nationalité",
      suspected_person_id_type: "Type pièce d’identité",
      suspected_person_id_issuer: "L'émetteur d'identité",
      suspected_person_id_expiry_date:
        "Date d'expiration de la pièce d'identité",
      suspected_person_birth_date: "Date de Naissance",
      suspected_person_birth_place: "Lieu de naissance",
      suspected_person_gender: "Sexe",
      suspected_person_marital_status: "État civil",
      suspected_person_workplace_name: "Nom du lieu de travail",
      suspected_person_employer_name: "Nom l’employeur",
      suspected_person_employer_address: "Adresse de l'employeur",
      suspected_person_employer_postal_code: "Code postal de l'employeur",
      suspected_person_employer_phone_number:
        "Numéros de téléphone de l'employeur",
      suspected_person_employer_activity:
        "La nature de l'activité de l'employeur",
      suspected_person_annual_income: "Le revenu annuel",
      suspected_person_income_source: "Source de revenu annuel",
      joint_account_partner_name: "Nom du partenaire pour les comptes joints",
      joint_account_partner_address:
        "Adresse du partenaire pour les comptes joints",
    },
    additional_info_profession: {
      suspected_person_profession_type: "Type de profession",
      suspected_person_profession_registration_number:
        "Numéro d'enregistrement de la profession",
      suspected_person_workplace_address: "Adresse du lieu de travail",
      suspected_person_workplace_email: "Email du lieu de travail",
      suspected_person_workplace_postal_box: "Boîte postale du lieu de travail",
      suspected_person_workplace_phone_number:
        "Numéro(s) de téléphone du lieu de travail",
    },
    beneficial_owner_info: {
      beneficial_owner_name: "Nom",
      beneficial_owner_nationality: "Nationalité",
      beneficial_owner_id_type: "Type pièce d’identité",
      beneficial_owner_id_number: "Numéro de pièce d’identité",
      beneficial_owner_relationship_with_suspect:
        "La relation du véritable bénéficiaire avec le suspect",
    },
  },
  suspected_entity: {
    entity_info: {
      suspected_entity_commercial_name: "Le nom commercial",
      suspected_entity_legal_form: "Forme juridique",
      suspected_entity_registration_number:
        "Numéro d'enregistrement commercial",
      suspected_entity_creation_date: "Date de création",
      suspected_entity_capital: "Capital social",
      suspected_entity_activity_description:
        "Description de l'activité selon le registre du commerce",
      suspected_entity_address: "Adresse",
      suspected_entity_email: "Email",
      suspected_entity_postal_code: "Code Postal",
      suspected_entity_phone_number: "Numéro(s) de téléphone",
      suspected_entity_fax_number: "Numéro(s) de fax",
      suspected_entity_management_names_and_titles:
        "Noms et prescription de la haute direction",
      authorized_person_names:
        "Les personnes autorisées à traiter au nom la personne morale",
      suspected_entity_relationship_start_date:
        "La date du début des relations avec la personne morale",
    },
    beneficial_owner_info: {
      entity_beneficial_owner_name: "Nom",
      entity_beneficial_owner_nationality: "Nationalité",
      entity_beneficial_owner_id_type: "Type pièce d’identité",
      entity_beneficial_owner_id_number: "Numéro de pièce d’identité",
      entity_beneficial_owner_relationship_with_entity:
        "La relation du bénéficiaire réel avec la personne morale suspectée",
    },
  },
  relationship_nature: {
    suspect_name: "Nom du suspect",
    relationship_type: "Type de relation",
    relationship_still_exists: "La relation existe-t-elle toujours ?",
  },
  related_persons: {
    linked_person_name: "Nom",
    linked_person_id_type: "Type pièce d’identité",
    linked_person_id_number: "Numéro de pièce d’identité",
    linked_person_relationship_to_transaction:
      "Lien avec l'opération suspectée",
    linked_person_relationship_to_reporting_entity:
      "Relation avec la partie déclarante",
  },
  suspected_operation_details: {
    transaction_date: "Date",
    transaction_type: "Type d’opération",
    suspicion_indications_and_reasons: "Indications et motifs du soupçons",
    suspicion_motives: "Raisons du soupçon",
  },
  suspect_accounts_info: {
    account_type: "Type de compte",
    account_opening_date: "Date d'ouverture du compte",
    account_branch_name: "L’agence qui a ouvert le compte",
    account_number: "Numéro de compte",
    account_purpose: "But de la transaction sur le compte",
    account_currency: "Devise du compte",
    account_balance: "Solde du compte",
    account_balance_date: "En date de",
    account_study_period_start_date: "Période d'étude du compte",
    total_credit_movements:
      "Le mouvement total de crédit sur le compte pendant la période d'étude",
    total_debit_movements:
      "Le mouvement total de débit sur le compte pendant la période d'étude",
    highest_credit_balance:
      "Le solde créditeur le plus élevé sur le compte au cours des trois dernières années",
    linked_accounts: "Comptes liés (A citer)",
  },
  measures_taken: {
    actions_taken_suspect_name: "Nom du suspect",
    actions_taken_with_suspect: "Mesures prises avec le suspect",
    additional_actions_taken: "Autres mesures prises",
  },
  documents_to_provide: {
    client_identification_documents:
      "Documents d'identification du client et formulaire d'ouverture de compte",
    client_due_diligence_documents:
      "Diligence raisonnable et documents d'identification du client pour la personne suspectée",
    other_account_opening_documents:
      "Autres documents obtenus lors de l'ouverture d'un compte ou de l'établissement d'une relation d'affaires",
    account_statements: "Relevé des comptes",
    suspicious_transaction_copies:
      "Photocopies de transactions financières suspectes",
    additional_related_documents:
      "Tout autre document à l'appui de la transaction suspectée (lié à la transaction suspectée)",
  },
};

export const formFieldsTypes = {};

export const sectionsAr = {
  reporting_entity: "الجهة المبلغة",
  declaration_responsible_person: "الشخص المسؤول عن البلاغ",
  suspicion_declaration: "بلاغ الاشتباه",
  suspected_individual: {
    individual_info: "معلومات عن الشخص الطبيعي المشتبه فيه",
    additional_info_profession:
      "معلومات إضافية عن الشخص الطبيعي المشتبه فيه الذي يزاول مهنة",
    beneficial_owner_info:
      "بيانات المستفيد الحقيقي من الشخص الطبيعي المشتبه فيه (في حالة اختلافه عن صاحب الحساب محل الإبلاغ)",
  },
  suspected_entity: {
    entity_info: "معلومات عن الشخص الاعتباري المشتبه فيه",
    beneficial_owner_info:
      "بيانات المستفيد الحقيقي من الشخص الاعتباري المشتبه فيه",
  },
  relationship_nature:
    "طبيعة علاقة الجهة المبلغة مع الشخص المشتبه فيه (طبيعي، اعتباري)",
  related_persons:
    "الأشخاص الآخرين ذوي الصلة بالعملية المشتبه فيها (المشتبه فيه التابع) (تستوفي لكل شخص على حدة)",
  suspected_operation_details: "تفاصيل العملية المشتبه فيها",
  suspect_accounts_info:
    "معلومات متعلقة بحسابات الشخص المشتبه فيه (تستوفي لكل حساب على حدة)",
  measures_taken:
    "الإجراءات التي اتخذتها الجهة المبلغة بالنسبة للشخص المشتبه فيه",
  documents_to_provide:
    "تلتزم الجهة المبلغة بموافاة وحدة التحريات المالية بما يلي",
};

export const sectionsFr = {
  reporting_entity: "Entité déclarante",
  declaration_responsible_person: "Personne responsable de la déclaration",
  suspicion_declaration: "La déclaration de soupçons",
  suspected_individual: {
    name: "Personne physique suspectée",
    options: {
      individual_info: "Informations sur la personne physique suspectée",
      additional_info_profession:
        "Informations supplémentaire relatives à la personne physique suspectée  qui exerce une profession",
      beneficial_owner_info:
        "Données du bénéficiaire effectif de la personne physique suspectée (dans le cas où il est différent du titulaire du compte visé par le soupçon)",
    },
  },
  suspected_entity: {
    name: "Personne morale suspectée",
    options: {
      entity_info: "Informations sur la personne morale suspectée",
      beneficial_owner_info:
        "Données du bénéficiaire effectif de la personne morale suspectée",
    },
  },
  relationship_nature:
    "La nature de la relation du déclarant avec la personne suspectée (Physique, Morale)",
  related_persons:
    "Autres personnes liées à l'opération suspectée (Personne Suspectée liée)",
  suspected_operation_details: "Détails de l'opération suspectée",
  suspect_accounts_info:
    "Informations liées aux comptes du suspect (à remplir pour chaque compte séparément)",
  measures_taken:
    "Mesures prises par l'autorité déclarante à l'égard de la personne suspectée",
  documents_to_provide:
    "La partie déclarante est tenue de fournir à la Cellule de Renseignement Financier (UMEF) les éléments suivants",
};

export const fieldsTranslation = (lang) => {
  let items = {};
  for (let item of Object.keys(formFieldsFr)) {
    if (typeof formFieldsFr[item] === "string") {
      items[item] = formFieldsFr[item];
    } else {
      for (let itemj of Object.keys(formFieldsFr[item])) {
        items[itemj] = formFieldsFr[item][itemj];
      }
    }
  }

  items["analytics_result"] = "Rapport de l'analyste";
  items["manager_result"] = "Résultat final";

  return items;
};
