let id_type = {
  fr: ["Carte d'identité", "Passeport", "Carte de séjour"],
  ar: ["بطاقة تعريف", "بطاقة الإقامة", "جواز السفر"],
};

const countries = [
  "Mauritanie",
  "Afghanistan",
  "Afrique du Sud",
  "Albanie",
  "Algérie",
  "Allemagne",
  "Andorre",
  "Angola",
  "Antigua-et-Barbuda",
  "Arabie saoudite",
  "Argentine",
  "Arménie",
  "Australie",
  "Autriche",
  "Azerbaïdjan",
  "Bahamas",
  "Bahreïn",
  "Bangladesh",
  "Barbade",
  "Belgique",
  "Belize",
  "Bénin",
  "Bhoutan",
  "Biélorussie",
  "Birmanie",
  "Bolivie",
  "Bosnie-Herzégovine",
  "Botswana",
  "Brésil",
  "Brunei",
  "Bulgarie",
  "Burkina Faso",
  "Burundi",
  "Cambodge",
  "Cameroun",
  "Canada",
  "Cap-Vert",
  "Centrafrique",
  "Chili",
  "Chine",
  "Chypre",
  "Colombie",
  "Comores",
  "Congo-Brazzaville",
  "Congo-Kinshasa",
  "Corée du Nord",
  "Corée du Sud",
  "Costa Rica",
  "Côte d'Ivoire",
  "Croatie",
  "Cuba",
  "Danemark",
  "Djibouti",
  "Dominique",
  "Égypte",
  "Émirats arabes unis",
  "Équateur",
  "Érythrée",
  "Espagne",
  "Estonie",
  "Eswatini",
  "États-Unis",
  "Éthiopie",
  "Fidji",
  "Finlande",
  "France",
  "Gabon",
  "Gambie",
  "Géorgie",
  "Ghana",
  "Grèce",
  "Grenade",
  "Guatemala",
  "Guinée",
  "Guinée-Bissau",
  "Guinée équatoriale",
  "Guyana",
  "Haïti",
  "Honduras",
  "Hongrie",
  "Îles Salomon",
  "Inde",
  "Indonésie",
  "Irak",
  "Iran",
  "Irlande",
  "Islande",
  "Israël",
  "Italie",
  "Jamaïque",
  "Japon",
  "Jordanie",
  "Kazakhstan",
  "Kenya",
  "Kirghizistan",
  "Kiribati",
  "Kosovo",
  "Koweït",
  "Laos",
  "Lesotho",
  "Lettonie",
  "Liban",
  "Liberia",
  "Libye",
  "Liechtenstein",
  "Lituanie",
  "Luxembourg",
  "Macédoine du Nord",
  "Madagascar",
  "Malaisie",
  "Malawi",
  "Maldives",
  "Mali",
  "Malte",
  "Maroc",
  "Marshall",
  "Maurice",
  "Mexique",
  "Micronésie",
  "Moldavie",
  "Monaco",
  "Mongolie",
  "Monténégro",
  "Mozambique",
  "Namibie",
  "Nauru",
  "Népal",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norvège",
  "Nouvelle-Zélande",
  "Oman",
  "Ouganda",
  "Ouzbékistan",
  "Pakistan",
  "Palaos",
  "Panama",
  "Papouasie-Nouvelle-Guinée",
  "Paraguay",
  "Pays-Bas",
  "Pérou",
  "Philippines",
  "Pologne",
  "Portugal",
  "Qatar",
  "République centrafricaine",
  "République dominicaine",
  "République tchèque",
  "Roumanie",
  "Royaume-Uni",
  "Russie",
  "Rwanda",
  "Saint-Christophe-et-Niévès",
  "Sainte-Lucie",
  "Saint-Marin",
  "Saint-Vincent-et-les-Grenadines",
  "Salvador",
  "Samoa",
  "São Tomé-et-Principe",
  "Sénégal",
  "Serbie",
  "Seychelles",
  "Sierra Leone",
  "Singapour",
  "Slovaquie",
  "Slovénie",
  "Somalie",
  "Soudan",
  "Soudan du Sud",
  "Sri Lanka",
  "Suède",
  "Suisse",
  "Suriname",
  "Syrie",
  "Tadjikistan",
  "Tanzanie",
  "Tchad",
  "Thaïlande",
  "Timor oriental",
  "Togo",
  "Tonga",
  "Trinité-et-Tobago",
  "Tunisie",
  "Turkménistan",
  "Turquie",
  "Tuvalu",
  "Ukraine",
  "Uruguay",
  "Vanuatu",
  "Vatican",
  "Venezuela",
  "Vietnam",
  "Yémen",
  "Zambie",
  "Zimbabwe",
];

export const inputsOptions = {
  reporting_entity_type: {
    fr: [
      "Banques",
      "institutions de microfinance",
      "Bureaux de change",
      "Compagnies d'assurance et de réassurance",
      "Caisse de dépôt et de développement",
      "La Poste",
      "Sociétés de transfert d'argent",
      "Avocats",
      "Notaires",
      "Experts comptables",
      "Négociants en métaux et pierres précieuses",
      "Agents immobiliers",
    ],
    ar: [
      "بنوك",
      "مؤسسات التمويل الصغيرة",
      "مكاتب الصرافة",
      "شركات التأمين وإعادة التأمين",
      "صندوق الإيداع والتنمية",
      "البريد",
      "مؤسسات تحويل الأموال",
      "المحامين",
      "الموثقين",
      "خبراء المحاسبة",
      "تجار المعادن والأحجار النفيسة",
      "الوكلاء العقاريين",
    ],
  },
  report_type: {
    fr: [
      "Nouvelle déclaration",
      "Déclaration de correction pour une déclaration envoyé précédemment",
      "Déclaration complémentaire d'une déclaration envoyé précédemment",
      "Demande d'informations à l’UMEF pour une déclaration envoyée précédemment",
    ],
    ar: [
      "بلاغ جديد",
      "بلاغ تصحيح لبلاغ سبق إرساله",
      "بلاغ إلحاقي لبلاغ سبق إرساله",
      "طلب معلومات من وحدة التحريات المالية لبلاغ سبق ارساله",
    ],
  },
  suspicion_type: {
    fr: [
      "Infraction soujacente",
      "Blanchiment d'argent",
      "Financement de Terorrisme",
      "Sanctions financières ciblées",
    ],
    ar: ["غسل أموال", "تمويل إرهاب", "جريمة أصلية ", "عقوبات مالية مستهدفة"],
  },
  // suspected_person_nationality: {
  //   fr: ["Mauritanienne", "Etrangère"],
  //   ar: ["موريتاني ", "أجنبي"],
  // },
  suspected_person_id_type: { ...id_type },
  suspected_person_gender: {
    fr: ["Masculin", "Féminin"],
    ar: ["ذكر", "أنثي"],
  },
  suspected_person_marital_status: {
    fr: ["Marié", "Célibataire"],
    ar: ["متزوج", "عازب"],
  },
  beneficial_owner_id_type: { ...id_type },
  entity_beneficial_owner_id_type: { ...id_type },
  relationship_type: {
    fr: [
      "client",
      "Un employé de l'entité déclarante",
      "Mandataire d'une personne physique ou morale",
      "Autorisé à traiter pour le compte de la personne morale",
      "Autre",
    ],
    ar: [
      "عميل",
      "موظف لدى الجهة المبلغة",
      "وكيل عن شخص طبيعي أو اعتباري ",
      "مفوض بالتعامل عن الشخص الاعتباري",
      "أخرى ",
    ],
  },
  relationship_still_exists: {
    fr: ["Oui ", "Non"],
    ar: ["نعم", "لا"],
  },
  linked_person_id_type: { ...id_type },
  linked_person_relationship_to_reporting_entity: {
    fr: [
      "Non existante",
      "Un employé de l'entité déclarante",
      "Mandataire d'une personne physique ou morale",
      "Autorisé à traiter",
      "Autre (précise)",
    ],
    ar: [
      "لا يوجد",
      "عميل",
      "موظف لدى الجهة المبلغة",
      "وكيل عن شخص طبيعي أو اعتباري",
      "مفوض بالتعامل عن الشخص الاعتباري",
      "أخرى (تحدد)",
    ],
  },
  actions_taken_with_suspect: {
    fr: ["Continuer à traiter", "Gel de compte", "Fermeture de compte"],
    ar: ["استمرار التعامل", "تجميد الحساب", "إغلاق الحساب"],
  },
  additional_actions_taken: {
    fr: [
      "Reporting à la banque centrale",
      "Informer les autorités d'enquête et de contentieux",
      "Autres",
    ],
    ar: ["إبلاغ البنك المركزي", "إبلاغ جهات التحقيق والتقاضي", "اخرى"],
  },
  suspected_person_nationality: { fr: countries, ar: [] },
  beneficial_owner_nationality: { fr: countries, ar: [] },
  entity_beneficial_owner_nationality: { fr: countries, ar: [] },
};
