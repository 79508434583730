import React, { useEffect, useState } from "react";
import { Api } from "../api";
import { LoadingComponent } from "../components/LoadingComponent";

export const SearchPage = () => {
  let [error, setError] = useState(false);
  let [loading, setLoading] = useState(true);
  let [searchIn, setSearchIn] = useState("person");
  let [query, setQuery] = useState("");

  let [info, setInfo] = useState(null);

  let init = () => {
    setLoading(true);
    setError(false);
    Api.get(`numbers`)
      .then((result) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <div className="bg-white p-3 rounded">
      <h2 className="px-3 mb-3">Requête</h2>

      <ul className="nav nav-pills justify-content-center mb-5">
        <li className="nav-item mx-2">
          <button
            className={
              "nav-link btn- px-4 py-3 " +
              (searchIn === "person" ? "active" : "border")
            }
            aria-current="page"
            onClick={(e) => setSearchIn("person")}
          >
            Personnes
          </button>
        </li>
        <li className="nav-item mx-2">
          <button
            className={
              "nav-link px-4 py-3 " +
              (searchIn === "company" ? "active" : "border")
            }
            aria-current="page"
            onClick={(e) => setSearchIn("company")}
          >
            Entreprises
          </button>
        </li>
      </ul>

      <div className="col-md-8 mx-auto mb-4">
        <input
          type="text"
          className="form-control form-control-lg"
          placeholder={"Recherche..."}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>

      {!query ? (
        <div></div>
      ) : searchIn === "company" ? (
        <div
          className="card mx-auto border-0"
          style={{
            width: "50rem",
            borderRadius: "12px",
            background: "linear-gradient(135deg, #ff7e5f, #feb47b)",
            color: "white",
          }}
        >
          <div className="card-body p-4">
            <h5
              className="card-title text-center mb-4"
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              Informations de l'entreprise
            </h5>

            <div style={{ fontSize: "1.2rem", lineHeight: "2" }}>
              <div className="d-flex justify-content-between mb-2">
                <span>Nom de l'entreprise:</span>
                <span>{company.name}</span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Numéro fiscal NIF:</span>
                <span>{company.nif}</span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Registre de commerce:</span>
                <span>{company.number}</span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Nom du propriétaire:</span>
                <span>{company.owner_name}</span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Ville:</span>
                <span>{company.city}</span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Date de création:</span>
                <span>{company.created_at}</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div
            className="card border-0 mx-auto"
            style={{
              width: "50rem",
              borderRadius: "12px",
              background: "linear-gradient(135deg, #6b73ff, #000dff)",
              color: "white",
            }}
          >
            <div className="card-body p-4">
              <h4
                className="card-title text-center mb-5"
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                }}
              >
                Informations Personnelles
              </h4>

              <div style={{ fontSize: "1.2rem", lineHeight: "2" }}>
                <div className="d-flex justify-content-between mb-2">
                  <span>Nom:</span>
                  <span>{person.name}</span>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <span>Date de naissance:</span>
                  <span>{person.birth_date}</span>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <span>Lieu de naissance:</span>
                  <span>{person.city}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

let company = {
  name: "La technologie pour tous SARL",
  number: "017774",
  nif: "RC0043",
  created_at: "01 Janvier 2023",
  owner_name: "Abdallah Muhammad Al-Amin",
  city: "Nouadhibou",
};
let person = {
  name: "Abdallah Muhammad Al-Amin Akah",
  birth_date: "12 janvier 1990",
  city: "Kiffa",
};
// La technologie pour tous SARL
// 017774
// RC0043
// 01 Janvier 2023
// Abdallah Muhammad Al-Amin
// Nouadhibou

// Abdallah Muhammad Al-Amin Akah
// 12 janvier 1990
// Kiffa
