import React from "react";

// Brouillon, Nouveau, En cours, En attente de révision par le directeur, Approfondissement, Fermé, Transféré à la justice
// "hidden", "preprocess", "process", "review", "search", "closed", "done"
export const DeclarationStatusComponent = ({ status, userType }) => {
  if (userType === "org") {
    if (status === "draft") {
      return <span className="badge bg-gray">Brouillon</span>;
    }
    return <span className="badge bg-info">Soumis</span>;
  }

  if (status == "process") {
    return <span className="badge bg-success">En cours</span>;
  }

  if (status == "review") {
    return <span className="badge bg-info">En attente de révision</span>;
  }

  if (status == "closed") {
    return <span className="badge bg-light text-dark">Archivé</span>;
  }

  if (status == "done") {
    return (
      <span className="badge bg-dark text-white">Transféré à la justice</span>
    );
  }

  return <span className="badge bg-warning">Nouveau</span>;
};

export const declarationStatusColor = {
  draft: "bg-gray",
  submitted: "bg-info",
  process: "bg-success",
  review: "bg-info",
  search: "bg-purple text-white",
  closed: "bg-light text-dark",
  done: "bg-dark text-white",
  org_process: "bg-success",
  org_done: "bg-dark text-white",
};

export const declarationStatusTranslation = {
  draft: "Brouillon",
  submitted: "Nouveau",
  process: "En cours",
  review: "En attente de révision",
  closed: "Archivé",
  done: "Transféré à la justice",
};

export const declarationStatuses = (userType) => {
  if (userType === "org") {
    return {
      draft: "Brouillon",
      org_process: "Soumis",
      // org_process: "En cours",
      // org_done: "Terminé",
    };
  }

  if (userType === "manager") {
    return {};
  }

  return {
    // my: "Mon",
    submitted: "Nouveau",
    process: "En cours",
    review: "En attente de révision",
    done: "Transféré à la justice",
    closed: "Archivé",
  };
};

export const declarationStatusesFilters = (userType) => {
  if (userType === "org") {
    return {
      "": "Tous",
      draft: "Brouillon",
      org_process: "Soumis",
      // org_process: "En cours",
      // org_done: "Terminé",
    };
  }

  if (userType === "manager") {
    return {};
  }

  return {
    // my: "Mon",
    "": "Tous",
    submitted: "Nouveau",
    analytics_process: "En cours",
    done: "Transféré à la justice",
    closed: "Archivé",
  };
};

export const declarationStatusesFiltersColors = {
  "": "btn-outline-primary",
  draft: "btn-outline-warning",
  org_process: "btn-outline-info",
  submitted: "btn-outline-warning",
  analytics_process: "btn-outline-success",
  done: "btn-outline-dark",
  closed: "btn-outline-secondary",
};

export const updateDeclarationStatuses = {
  done: "Transféré à la justice",
  process: "Approfondir la recherche",
  closed: "Archivage",
};

export const updateDeclarationStatusesColors = {
  done: "btn-success",
  process: "btn-info",
  closed: "btn-dark",
};
