import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { UsersPage } from "./pages/UsersPage";
import { LayoutPage } from "./pages/LayoutPage";
import { UsersList } from "./pages/Users/Index";
import { AddUser } from "./pages/Users/Add";
import { EditUser } from "./pages/Users/Edit";
import { ShowUser } from "./pages/Users/Show";
import { DeclarationsPage } from "./pages/DeclarationsPage";
import { DeclarationsList } from "./pages/declarations/Index";

import "./style.scss";
import "font-awesome/css/font-awesome.min.css";
import "../styles/core_ui.css";
import "../styles/simplebar.css";
import "simplebar/dist/simplebar.esm";
import { AddDeclaration } from "./pages/declarations/AddDeclaration";
import { UserContext } from "./store";
import { LoginPage } from "./pages/LoginPage";
import { DeclarationLogs } from "./pages/declarations/Actions/DeclarationLogs";
import { SearchPage } from "./pages/SearchPage";
import { HomePage } from "./pages/HomePage";

export const App = () => {
  let { user, isAuthenticated } = useContext(UserContext);
  // if (!isAuthenticated) return <></>;

  return (
    <Routes>
      <Route path="/" element={<LayoutPage />}>
        {!isAuthenticated ? (
          <Route path="*" element={<LoginPage />} />
        ) : (
          <>
            <Route
              index
              element={
                <Navigate
                  to={user.role === "admin" ? "/users" : "/declarations"}
                  replace
                />
              }
            />

            {["manager", "analytics"].includes(user.role) ? (
              <>
                <Route path="dashbaord" element={<HomePage />} />
                <Route path="query" element={<SearchPage />} />
              </>
            ) : (
              <></>
            )}

            {user.role === "admin" ? (
              <Route path="users" element={<UsersPage />}>
                <Route index element={<UsersList />} />
                <Route path="add" element={<AddUser />} />
                <Route path="edit/:id" element={<EditUser />} />
                <Route path="show/:id" element={<ShowUser />} />
              </Route>
            ) : (
              <></>
            )}
            {user.role !== "admin" ? (
              <Route path="declarations" element={<DeclarationsPage />}>
                <Route index element={<DeclarationsList />} />

                {user.role !== "org" ? (
                  <>
                    <Route
                      path="show/:id"
                      element={<AddDeclaration readOnly={true} />}
                    />
                    <Route
                      path="show/:id/logs"
                      element={<DeclarationLogs readOnly={true} />}
                    />
                  </>
                ) : (
                  <></>
                )}
                {user.role === "org" ? (
                  <Route path="create/:id?" element={<AddDeclaration />} />
                ) : (
                  <></>
                )}
              </Route>
            ) : (
              <></>
            )}
            <Route
              path="*"
              element={
                <Navigate
                  to={user.role === "admin" ? "/users" : "/declarations"}
                  replace
                />
              }
            />
          </>
        )}
      </Route>
    </Routes>
  );
};
